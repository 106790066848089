import React,{useEffect} from 'react';
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Col,
    Row,
    Container,
    Card,
    CardBody,
    CardImgOverlay,
    CardImg,
    CardTitle,
    CardText,
    Button
} from "reactstrap";
import Panel from "../../../../shared/components/Panel";
import {connect} from "react-redux";
import modelVenue from '../../../../Models/Venue';

const Images = ({venueImages,venueData}) => {

    const mv = new modelVenue();

    useEffect(()=>{

    },[venueImages,venueData]);

    const setImageAsFeatured = (e) => {
        e.preventDefault();
        let imageToFeatured = e.target.value;
        let formData= new FormData();
        formData.append('filename', imageToFeatured);
        formData.append('venueId', venueData.v_id);
        mv.setFeaturedImage(formData);
    };

    return (
        <Panel xl={12} lg={12} md={12} title="Images" subhead="Images">
            <div className={'row'}>
                {venueImages.map((image, index) => (
                    <Col
                        md="3"
                        className="my-2"
                        key={index}
                        // onClick={() => this.showModalImage(index)}
                    >
                        <Card className="venueImageCard image-card">
                            <CardImg src={process.env.REACT_APP_VENUE_IMG_ROOT + image.trim()} onError={(e) => e.target.src = (process.env.NODE_ENV === 'production') ? 'https://via.placeholder.com/300' : ""}/>
                            <CardImgOverlay className={'venueImageCardOverlay'} >
                                {/*<CardTitle className='text'>Weather</CardTitle>*/}
                                <Button className={'btn-primary btn'} onClick={setImageAsFeatured} value={image.trim()}>Set Featured</Button>
                                {/*<CardText className='text'>Summer</CardText>*/}
                            </CardImgOverlay>
                        </Card>
                    </Col>
                ))}


            </div>

        </Panel>
    );
};

export default connect(state => ({
    venueData:state.venue.venueData,
    venueImages:state.venue.venueImages,
}))(Images);

