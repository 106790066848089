import React, { useEffect,useState } from 'react';
import {
    Card, CardBody, Col, Row, Button, Alert, Modal,
} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Http from "../../../containers/App/Http";
import {connect} from "react-redux";

const rocket = `${process.env.PUBLIC_URL}/img/pricing_cards/001-rocket.svg`;

const ResetPassword = ({dispatch,isOpen, toggle, user,venueData, rtl,theme}) => {
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [email,setEmail] = useState(null);

    const handleSubmit=async ()=>{
        setProcessing(true);

        let subData = new FormData();
        subData.append('logName',email);

        Http.post("/auth/forgotPass",subData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    return (
      <>

              <Modal isOpen={isOpen} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="md">
                  <div className="modal__header">
                      <button
                          className="lnr lnr-cross modal__close-btn"
                          aria-label="modal__close-btn"
                          type="button"
                          onClick={toggle}
                      />
                      <h4 className="text-modal  modal__title">Reset Password</h4>
                  </div>

                  <div className="modal__body pt-0">
                      {successMsg !== false &&
                      <>
                          <h4>{successMsg}</h4>
                          <Button color={"danger"} className={"btn-sm mb-0 mt-2"} onClick={toggle}>Close<FontAwesomeIcon icon="user"/></Button>
                      </>
                      }
                      {!successMsg &&

                          <Col xs={'12'}>
                              <Alert color="danger" isOpen={!!errorMsg}>
                                  {errorMsg}
                              </Alert>
                              <Alert color="success" isOpen={!!successMsg}>
                                  {successMsg}
                              </Alert>
                              <div className="form__form-group mt-3">
                                  <Col xs={'12'} className={'mt-3 mb-3'}>
                                      <Alert color="info" className="p-3">
                                          <p><span className="bold-text">Information: </span>
                                              This action will send you an email with a link to reset your password. The link will be valid for 10 minutes
                                          </p>
                                      </Alert>
                                      <Row>
                                          <label htmlFor="resetPasswordForEmail" className="col-12 form__form-group-label col-form-label">Enter your email:</label>
                                          <div className="col-12">
                                              <input type="text" className="form-control form-control-sm" id="resetPasswordForEmail" onKeyUp={(e)=>{setEmail(e.target.value)}}/>
                                          </div>
                                      </Row>
                                  </Col>
                                  <div className="col-sm-12">
                                      <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={errorMsg || processing}>Submit</button>
                                  </div>
                              </div>
                          </Col>
                      }
                  </div>

              </Modal>

      </>
  );
};
export default connect(state => ({
    rtl: state.rtl,
    user : state.user.main,
    roles : state.user.roles,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(ResetPassword);