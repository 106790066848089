import React, { useEffect,useState } from 'react';
import {
    Card, CardBody, Col, Button, Alert, Modal,
} from 'reactstrap';
import SubscribeVenueToTier from './SubcribeVenueToTier';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardElement} from "@stripe/react-stripe-js";
import Http from "../../../../containers/App/Http";
import {connect} from "react-redux";
import ModelUser from "../../../../Models/User";
import ModelVenue from "../../../../Models/Venue";

const rocket = `${process.env.PUBLIC_URL}/img/pricing_cards/001-rocket.svg`;

const CardPremium = ({dispatch,view, user,venueData, rtl,theme}) => {
    const [subscribeTo,setSubscribeTo] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [tierInfo,setTierInfo] = useState({
        name:'Premium',
        productId:'',
        price:'$ 199.99',
        frequency:'Monthly'
    });
    const um = new ModelUser();
    const mu = new ModelVenue();

    const subscribeToThisTier = ()=>{
        setSubscribeTo(true);
    };

    const toggle = async () => {
        await setSubscribeTo(prevState => !prevState);
        // setError(false);
    };

    const handleSubmit=async ()=>{
        setProcessing(true);

        let subData = new FormData();
        subData.append('userId',user.u_id);
        subData.append('priceId',process.env.REACT_APP_SELF_SERVICE_PREMIUM_PRICE_ID);
        subData.append('venueId',venueData.v_id);

        Http.post("/sb/selfServeSubscribeVenue",subData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            // RELOAD THE USER OBJECT INTO REDUX
            um.getUserInfo();
            mu.getVenueData(venueData.v_id);

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    return (
      <>
        <Col md={6} xl={4} sm={12}>
            <Card>
                <CardBody className="pricing-card pricing-card--danger">
                    <div className="pricing-card__body">
                        <img className="pricing-card__img" src={rocket} alt="" />
                        <h3 className="pricing-card__plan">Premium</h3>
                        <hr />
                        <p className="pricing-card__price">$199.99<span>/mo</span></p>
                        <p className="pricing-card__feature">Higher Search Placement</p>
                        <p className="pricing-card__feature">Unlimited Leads</p>
                        <p className="pricing-card__feature">No Other Venue Recommendation</p>
                        <p className="pricing-card__feature">Phone Support</p>
                        {user.hasPaymentMethod &&
                            <Button className="pricing-card__button" color="primary" onClick={subscribeToThisTier}>Sign Up</Button>
                        }

                    </div>
                </CardBody>
            </Card>
        </Col>
          {subscribeTo &&
              <Modal isOpen={subscribeTo} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="md">
                  <div className="modal__header">
                      <button
                          className="lnr lnr-cross modal__close-btn"
                          aria-label="modal__close-btn"
                          type="button"
                          onClick={toggle}
                      />
                      <h4 className="text-modal  modal__title">Confirm</h4>
                  </div>

                  <div className="modal__body pt-0">
                      {successMsg !== false &&
                      <>
                          <h4>{successMsg}</h4>
                          <Button color={"danger"} className={"btn-sm mb-0 mt-2"} onClick={toggle}>Close<FontAwesomeIcon icon="user"/></Button>
                      </>
                      }
                      {!successMsg &&

                          <Col xs={'12'}>
                              <Alert color="danger" isOpen={!!errorMsg}>
                                  {errorMsg}
                              </Alert>
                              <Alert color="success" isOpen={!!successMsg}>
                                  {successMsg}
                              </Alert>
                              <div className="form__form-group mt-3">
                                  <Col xs={'12'} className={'mt-3 mb-3'}>
                                      <Alert color="info" className="p-3">
                                          <p><span className="bold-text">Information: </span>
                                              By confirming the action we will charge your payment method on file and upgrade your account.
                                              If you have any questions please reach out to our support team. Thank you!!!
                                          </p>
                                      </Alert>
                                      <h4>Tier: {tierInfo.name}</h4>
                                      <h4>Price: {tierInfo.price}</h4>
                                      <h4>Frequency: {tierInfo.frequency}</h4>
                                  </Col>
                                  <div className="col-sm-12">
                                      <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={errorMsg || processing}>Subscribe</button>
                                  </div>
                              </div>
                          </Col>





                      }
                  </div>

              </Modal>
          }
      </>
  );
};
export default connect(state => ({
    rtl: state.rtl,
    user : state.user.main,
    roles : state.user.roles,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(CardPremium);